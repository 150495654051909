<template>
    <div>
        <div class="banner" :style="cover">
            <div class="article-info-container">
              </div>
        </div>
        <v-card class="blog-container">
            <div><a href="/articles/108" target="_blank" style="text-decoration: underline">参考画图参数</a></div>
            <div class="wrapper">
                <iframe src="http://sd.formoji.fun/static"></iframe>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    created() {
    },
    components: {

    },
    data: function () {
        return {
            current: 1,
            count: 0,
        };
    },
    methods: {
    },
    computed: {
        cover() {
            var cover = "";
            this.$store.state.blogInfo.pageList.forEach(item => {
                if (item.pageLabel == "sd") {
                    cover = item.pageCover;
                }
            });
            return "background: url(" + cover + ") center center / cover no-repeat";
        }
    },
};
</script>
<style>
.wrapper {
    text-align: center;
    overflow: auto;
    
}

iframe {
    display: inline-block;
    margin-top: 20px;
    /* 可以根据需要调整 */
    width: 100%;
    min-height: 1100px;

    height: 100%;
    display: block;
}
</style>